import React from "react"
import { Link } from "gatsby"

import Layout from "../../../../components/layout"
import SEO from "../../../../components/seo"

function BayOfIslandsIndex(props) {
  return (
    <>
      <SEO title="Bay of Islands Day Trips | Cape Reinga | 90 Mile Beach - awesomeNZ" />
      <Layout
        layoutType="typography TourHolderPage"
        slideshow={
          <ul class="slideshow-slides">
            <li class="slideshow-slide first">
              <img
                src={require("../../../../images/sliders/bayofislands-landscape-view.jpg")}
                alt=""
                class="first image"
              />
            </li>
          </ul>
        }
        hasSliderHandles={false}
        hasSliderBlurSmall={true}
        content={
          <>
            <div id="TourHolderPage" class="clearfix">
              <div class="title-div">
                <h1>Bay of Islands</h1>
                <h3>
                  Cruise, swim and frolic in the birthplace of New Zealand
                </h3>
                <Link
                  class="alltours"
                  to="/home/new-zealand-tours/bay-of-islands-tours#tours-section"
                >
                  <p>View all Bay of Islands tours &gt;</p>
                </Link>
              </div>
              <div class="destination-content">
                {/* ... */}
                <p>
                  The Bay of Islands is a stunning region north of Auckland.
                  Relax on sandy beaches, travel to the top of the country or
                  explore the Bay's 144 islands and warm, sheltered waters
                  abundant in marine life.
                </p>
                <p>
                  From the Bay of Islands, take a day tour to{" "}
                  <strong>
                    <Link to="/home/new-zealand-tours/bay-of-islands-tours/cape-reinga-via-90-mile-beach">
                      Cape Reinga
                    </Link>{" "}
                  </strong>
                  at the very tip of New Zealand and see the spot where the
                  Tasman Sea and Pacific Ocean collide. The Cape has an
                  important place in Māori legend - spend some time at this
                  special spot and you'll surely agree it has a very special
                  atmosphere. During your Cape Reinga day tour you'll also visit
                  the<span>&nbsp;</span>Puketi Forest, home to ancient Kauri
                  trees, and travel along spectacular{" "}
                  <strong>
                    <Link to="/home/new-zealand-tours/bay-of-islands-tours/cape-reinga-via-90-mile-beach">
                      90 Mile Beach
                    </Link>
                  </strong>{" "}
                  before sand-boarding down its giant sand dunes (if you're
                  feeling adventurous!).
                </p>
                <p>
                  Many visitors to the Bay of Islands base themselves in the
                  small resort town of Paihia. Across the harbour is historic
                  Russell, today a charming village of boutique shops, eateries
                  and historic buildings, it was once a lawless open port, known
                  as the 'Hellhole of the Pacific'!
                </p>
                {/* ... */}
              </div>
              <div id="tours-section" class="row">
                <div class="tour-item six columns tag-tour first-tour">
                  <h3>Our most popular tour</h3>
                  <div class="tour-image">
                    <Link
                      to="/home/new-zealand-tours/bay-of-islands-tours/cape-reinga-via-90-mile-beach"
                      class="tour-pic"
                    >
                      <img
                        src={require("../../../../images/tours/cape-reinga-tile.jpg")}
                        alt="Click here to find out more about this tour"
                        width="172"
                        height="126"
                      />
                    </Link>
                  </div>
                  <div class="tour-details">
                    <h2>Cape Reinga &amp; 90 Mile Beach</h2>
                    <div class="tour-details-prices">
                      <span class="tour-details-prices-from">From </span>
                      <span class="tour-details-prices-price">$140.00</span>
                    </div>
                    <span class="clearfix">
                      <Link to="/home/new-zealand-tours/bay-of-islands-tours/cape-reinga-via-90-mile-beach">
                        Find out more &gt;
                      </Link>
                    </span>
                  </div>
                  <div class="clear"></div>
                </div>

                <div class="tour-item six columns tag-tour">
                  <div class="tour-image">
                    <Link
                      to="/home/new-zealand-tours/bay-of-islands-tours/cape-reinga-via-90-mile-beach"
                      class="tour-pic"
                    >
                      <img
                        src={require("../../../../images/tours/cape-reinga-tile.jpg")}
                        alt="Click here to find out more about this tour"
                        width="172"
                        height="126"
                      />
                    </Link>
                  </div>
                  <div class="tour-details">
                    <h2>Cape Reinga &amp; 90 Mile Beach</h2>
                    <div class="tour-details-prices">
                      <span class="tour-details-prices-from">From </span>
                      <span class="tour-details-prices-price">$140.00</span>
                    </div>
                    <span class="clearfix">
                      <Link to="/home/new-zealand-tours/bay-of-islands-tours/cape-reinga-via-90-mile-beach">
                        Find out more &gt;
                      </Link>
                    </span>
                  </div>
                  <div class="clear"></div>
                </div>

                <div class="tour-item six columns tag-tour">
                  <div class="tour-image">
                    <Link
                      to="/home/new-zealand-tours/bay-of-islands-tours/awesome-combo"
                      class="tour-pic"
                    >
                      <img
                        src={require("../../../../images/tours/combo-cruise-dolphin-seeker.jpg")}
                        alt="Click here to find out more about this tour"
                        width="172"
                        height="126"
                      />
                    </Link>
                  </div>
                  <div class="tour-details">
                    <h2>Awesome Combo - Double Dip</h2>
                    <div class="tour-details-prices">
                      <span class="tour-details-prices-from">From </span>{" "}
                      <span class="tour-details-prices-price">$228.00</span>
                    </div>
                    <span class="clearfix">
                      <Link to="/home/new-zealand-tours/bay-of-islands-tours/awesome-combo">
                        Find out more &gt;
                      </Link>
                    </span>
                  </div>
                  <div class="clear"></div>
                </div>
              </div>
            </div>
          </>
        }
      />
    </>
  )
}

export default BayOfIslandsIndex
